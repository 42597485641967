<template>
  <div
    class="my-auto hidden h-full w-full items-center justify-center bg-white p-2 group-hover:bg-slate-100 @lg:flex"
  >
    <img
      v-if="image"
      :alt="title"
      class="max-h-[48px]"
      :src="`${image}?v=${publicationInfo.dataHash.auction}`"
    />
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'

const { image, title, publicationInfo } = inject('auction') as DisplayingAuction
</script>
